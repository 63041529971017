import queryString from 'query-string';
import { get } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE = `${API_URL}/reviews/mini`;
getById.operation = 'READ';
getById.byId = true;
export function getById(reviewId) {
    return get(`${BASE}/${reviewId}`);
}
getAllRelevant.operation = 'READ';
// eslint-disable-next-line no-unused-vars
export function getAllRelevant(userId, startDate, endDate) {
    const qS = queryString.stringify({ userId, startDate, endDate });
    return get(`${BASE}/relevant-for-user?${qS}`);
}
