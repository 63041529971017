import { get, put } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE = `${API_URL}/user-import-configurations`;
export function createApi(system) {
    const api = {
        saveConfig: (payload) => {
            return put(`${BASE}/${system}`, {
                postData: payload,
            });
        },
        getConfig: (companyId) => {
            const query = companyId ? `?company=${companyId}` : '';
            return get(`${BASE}/${system}${query}`);
        },
        resetConfig: (companyId) => {
            return put(`${BASE}/${companyId}/${system}`);
        },
    };
    api.saveConfig.operation = 'UPDATE';
    api.getConfig.operation = 'READ';
    api.resetConfig.operation = 'COMMAND';
    api.resetConfig.invalidates = ['getConfig'];
    return api;
}
