import { get, post, patch } from '../request';
import { API_URL } from '../../../app-config/constants';
const RESOURCE = `${API_URL}/praise/wall/token`;
getToken.operation = 'READ';
export function getToken() {
    return get(RESOURCE);
}
refreshToken.operation = 'CREATE';
export function refreshToken() {
    return post(`${RESOURCE}/refresh`);
}
activateToken.operation = 'UPDATE';
export function activateToken(token) {
    return patch(`${RESOURCE}/activate`, { getData: { active: token.active } });
}
