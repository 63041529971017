import { post } from '../request';
import { API_URL } from '../../../app-config/constants';
import moment from 'moment';
const ISO_DATE_ONLY = 'YYYY-MM-DD';
triggerPraiseExport.operation = 'COMMAND';
export function triggerPraiseExport(format, fromDate, untilDate) {
    return post(`${API_URL}/praise-export`, {
        getData: {
            format,
            from: fromDate ? moment(fromDate).format(ISO_DATE_ONLY) : undefined,
            until: untilDate ? moment(untilDate).format(ISO_DATE_ONLY) : undefined,
        },
    });
}
