import { get, post, put, remove } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_URL = `${API_URL}/review`;
getReview.operation = 'READ';
getReview.byId = true;
export function getReview(reviewId) {
    return get(`${BASE_URL}/${reviewId}`);
}
getReviewPreviewQuestionnaire.operation = 'READ';
getReviewPreviewQuestionnaire.byId = true;
export function getReviewPreviewQuestionnaire(cycleId) {
    return get(`${BASE_URL}/preview/questionnaire/${cycleId}`);
}
export function getPreviewReviewForCycleModel(model) {
    return post(`${BASE_URL}/preview/questionnaire`, { postData: { model } });
}
getReviewsForReviewee.operation = 'READ';
export function getReviewsForReviewee(revieweeId) {
    return get(`${BASE_URL}/about/${revieweeId}`);
}
notifyUpdate.operation = 'UPDATE';
export function notifyUpdate(review) {
    return Promise.resolve(review);
}
answerAssessment.operation = 'COMMAND';
export function answerAssessment(reviewId, assessmentId, assessmentAnswer) {
    return post(`${BASE_URL}/${reviewId}/answerAssessment/${assessmentId}`, {
        postData: assessmentAnswer,
    });
}
shareAssessment.operation = 'COMMAND';
export function shareAssessment(reviewId, assessmentId) {
    return post(`${BASE_URL}/${reviewId}/share/${assessmentId}`);
}
hideAssessment.operation = 'COMMAND';
export function hideAssessment(reviewId, assessmentId) {
    return post(`${BASE_URL}/${reviewId}/hide/${assessmentId}`);
}
signReview.operation = 'COMMAND';
export function signReview(reviewId, note) {
    const postData = { note };
    return post(`${BASE_URL}/${reviewId}/sign`, { postData });
}
updateContributingReviewers.operation = 'COMMAND';
export function updateContributingReviewers(reviewId, contributingReviewers) {
    return put(`${BASE_URL}/${reviewId}/updateContributingReviewers`, { postData: contributingReviewers });
}
const toAttachmentUrl = reviewId => `${BASE_URL}/${reviewId}/attachment`;
addAttachment.operation = 'COMMAND';
export function addAttachment(reviewId, createCommand) {
    return post(toAttachmentUrl(reviewId), { postData: createCommand });
}
deleteAttachment.operation = 'COMMAND';
export function deleteAttachment(reviewId, attachmentId) {
    return remove(`${toAttachmentUrl(reviewId)}/${attachmentId}`);
}
