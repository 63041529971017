import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
getDateTimeSettings.operation = 'READ';
export function getDateTimeSettings(companyId) {
    return get(`${API_URL}/company-settings/${companyId}/date-time`);
}
setDateTimeSettings.operation = 'COMMAND';
export function setDateTimeSettings(companyId, dateTimeSettings) {
    return put(`${API_URL}/company-settings/${companyId}/date-time`, { postData: dateTimeSettings });
}
