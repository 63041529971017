import { get, post } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE = `${API_URL}/user-import`;
const getQuery = (companyId) => {
    const companyQuery = companyId ? `&company=${companyId}` : '';
    return `?fields=$user-import.list${companyQuery}`;
};
export function createApi(system) {
    return {
        getHistory: (companyId) => {
            return get(`${BASE}/${system}/simulations${getQuery(companyId)}`);
        },
        getHistoryDetails: (id) => {
            return get(`${BASE}/${system}/simulation/${id}${getQuery()}`);
        },
        startPreview: (companyId) => {
            return post(`${BASE}/${system}/preview${getQuery(companyId)}`, { postData: null });
        },
        getPreview: (id) => {
            return get(`${BASE}/${system}/preview/${id}${getQuery()}`);
        },
        doPreview: (companyId) => {
            return post(`${BASE}/${system}/simulate${getQuery(companyId)}`, { postData: null });
        },
        doImport: () => {
            return post(`${BASE}/${system}`, { postData: null });
        },
    };
}
