import { get as httpGet, post } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_URL = `${API_URL}/anytime-feedback-response`;
const toUrl = (requestId, reviewerId) => `${BASE_URL}/${requestId}/${reviewerId}`;
const executeCommand = (requestId, reviewerId, command, postData) => post(`${toUrl(requestId, reviewerId)}/${command}`, { postData });
get.operation = 'READ';
export function get(requestId, reviewerId) {
    return httpGet(toUrl(requestId, reviewerId));
}
share.operation = 'COMMAND';
export function share(requestId, reviewerId) {
    return executeCommand(requestId, reviewerId, 'share');
}
reopen.operation = 'COMMAND';
export function reopen(requestId, reviewerId) {
    return executeCommand(requestId, reviewerId, 'reopen');
}
decline.operation = 'COMMAND';
export function decline(requestId, reviewerId) {
    return executeCommand(requestId, reviewerId, 'decline');
}
answer.operation = 'COMMAND';
export function answer(requestId, reviewerId, answers) {
    return executeCommand(requestId, reviewerId, 'answer', answers);
}
