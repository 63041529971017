import { find } from 'lodash/fp';
import { get, post, put, remove } from '../request';
import { API_URL } from '../../../app-config/constants';
const prependOnFirstPage = (offset, x, xs) => {
    if (offset === 0) {
        return [x, ...xs];
    }
};
getPraise.operation = 'READ';
getPraise.updateOnCreate = (args, x, xs) => prependOnFirstPage(args[0].offset, x, xs);
export function getPraise({ offset, limit }) {
    return get(`${API_URL}/praise`, {
        getData: {
            fields: '$message.list',
            limit,
            offset,
        },
    });
}
getPraiseBySomeone.operation = 'READ';
getPraiseBySomeone.updateOnCreate = ([{ offset, userId }], x, xs) => {
    if (offset === 0 && x.author && x.author.id === userId) {
        return [x, ...xs];
    }
};
export function getPraiseBySomeone({ offset, limit, userId, startDate, endDate, }) {
    return get(`${API_URL}/praise`, {
        getData: {
            fields: '$message.list',
            limit: limit.toString(),
            offset: offset.toString(),
            authorId: userId,
            startDate,
            endDate,
        },
    });
}
getPraiseAboutSomeone.operation = 'READ';
getPraiseAboutSomeone.updateOnCreate = ([{ offset, userId }], x, xs) => {
    if (offset === 0 && find(recipient => recipient.id === userId, x.recipients)) {
        return [x, ...xs];
    }
};
export function getPraiseAboutSomeone({ offset, limit, userId, startDate, endDate, }) {
    return get(`${API_URL}/praise`, {
        getData: {
            fields: '$message.list',
            limit: limit.toString(),
            offset: offset.toString(),
            recipientId: userId,
            startDate,
            endDate,
        },
    });
}
getPraiseItem.operation = 'READ';
getPraiseItem.byId = true;
export function getPraiseItem(id) {
    return get(`${API_URL}/praise/${id}?fields=$message.list`);
}
createPraise.operation = 'CREATE';
export function createPraise(payload) {
    return post(`${API_URL}/praise`, { postData: payload });
}
updatePraise.operation = 'COMMAND';
export function updatePraise(payload, id) {
    return put(`${API_URL}/praise/${id}`, { postData: payload });
}
updatePraiseInternally.operation = 'UPDATE';
export function updatePraiseInternally(praise) {
    return Promise.resolve(praise);
}
likePraise.operation = 'COMMAND';
export function likePraise(id) {
    return put(`${API_URL}/praise/${id}/upvote`);
}
unlikePraise.operation = 'COMMAND';
export function unlikePraise(id) {
    return put(`${API_URL}/praise/${id}/downvote`);
}
removePraise.operation = 'DELETE';
export function removePraise(id) {
    return remove(`${API_URL}/praise/${id}`);
}
// Praise Wall
getPraiseForWall.operation = 'READ';
export function getPraiseForWall(token) {
    return get(`/api/praise/wall?token=${token}`);
}
