import { get } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_PATH = `${API_URL}/meetings/metadata`;
export function getMetadata(q) {
    return get(BASE_PATH, {
        getData: {
            participants: q.participants.join(','),
            startDate: q.startDate,
            endDate: q.endDate,
            limit: q.limit ? q.limit.toString() : undefined,
        },
    });
}
