import { get, post, put, remove } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_URL = `${API_URL}/praise`;
const FIELDS = '?fields=$message.list';
const urlWithFields = url => `${BASE_URL}${url}${FIELDS}`;
getCommentsForPraise.operation = 'READ';
getCommentsForPraise.updateOnCreate = ([praiseId], comment, comments) => {
    if (comment.masterMessageId === praiseId) {
        return [...comments, comment];
    }
};
export function getCommentsForPraise(praiseId) {
    return get(urlWithFields(`/${praiseId}/comments`));
}
createComment.operation = 'CREATE';
export function createComment(comment) {
    const praiseId = comment.masterMessageId;
    return post(urlWithFields(`/${praiseId}/comments`), { postData: comment });
}
updateComment.operation = 'COMMAND';
export function updateComment(comment) {
    const praiseId = comment.masterMessageId;
    return put(urlWithFields(`/${praiseId}/comments/${comment.id}`), { postData: comment });
}
deleteComment.operation = 'DELETE';
export function deleteComment(commentId, praiseId) {
    return remove(urlWithFields(`/${praiseId}/comments/${commentId}`));
}
