import { get, put } from '../request';
import { API_URL } from '../../../app-config/constants';
const getBaseUrl = (type) => `${API_URL}/feedback/template/${type}/order`;
getOrder.operation = 'READ';
export function getOrder(type = 'ANYTIME') {
    return get(getBaseUrl(type));
}
updateOrder.operation = 'UPDATE';
export function updateOrder(order, type = 'ANYTIME') {
    return put(getBaseUrl(type), { postData: order });
}
