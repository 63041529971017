import { get } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_URL = `${API_URL}/unified-feedback`;
getById.operation = 'READ';
export function getById(id) {
    return get(`${BASE_URL}/details/${id}`);
}
export function getFeedbackDetailsViewedAs(feedbackId, view) {
    return get(`${BASE_URL}/details/${feedbackId}/view-as/${view}`);
}
