import { get, post, remove } from '../request';
import { API_URL } from '../../../app-config/constants';
getByUserId.operation = 'READ';
export function getByUserId(userId) {
    return get(`${API_URL}/users/` + userId + '/calendars');
}
getForCurrentUser.operation = 'READ';
export function getForCurrentUser() {
    return get(`${API_URL}/user-calendar`);
}
deleteCalendar.operation = 'DELETE';
deleteCalendar.invalidates = ['getForCurrentUser'];
export function deleteCalendar(calendarId) {
    return remove(`${API_URL}/calendars/${calendarId}`);
}
create.operation = 'CREATE';
export function create() {
    const postData = { name: 'Small Improvements' };
    return post(`${API_URL}/calendars`, { postData });
}
