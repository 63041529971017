import { get, post } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE_URL = `${API_URL}/assessment`;
getAssessment.operation = 'READ';
export function getAssessment(assessmentId) {
    return get(`${BASE_URL}/${assessmentId}`);
}
getAssessmentsForReview.operation = 'READ';
export function getAssessmentsForReview(reviewId) {
    return get(`${BASE_URL}?reviewId=${reviewId}`);
}
getAssessmentsForPreviewQuestionnaire.operation = 'READ';
export function getAssessmentsForPreviewQuestionnaire(cycleId) {
    return get(`${BASE_URL}/preview/questionnaire/${cycleId}`);
}
export function getPreviewAssessmentsForCycleModel(model) {
    return post(`${BASE_URL}/preview/questionnaire`, { postData: { model } });
}
createReviewerAssessmentForSelf.operation = 'COMMAND';
export function createReviewerAssessmentForSelf(reviewId) {
    return post(`${BASE_URL}s`, {
        postData: {
            reviewId,
        },
    });
}
answerAssessment.operation = 'COMMAND';
export function answerAssessment(assessmentId, assessmentAnswers) {
    return post(`${BASE_URL}/${assessmentId}/answers`, {
        postData: assessmentAnswers,
    });
}
moderateAssessment.operation = 'COMMAND';
export function moderateAssessment(assessmentId, assessmentAnswers, authorId) {
    return post(`${BASE_URL}/${assessmentId}/answers/${authorId}`, {
        postData: assessmentAnswers,
    });
}
indicateDone.operation = 'COMMAND';
export function indicateDone(assessmentId, indicated) {
    return post(`${BASE_URL}/${assessmentId}/indicatedDone`, {
        postData: indicated,
    });
}
