import { post, get } from '../request';
const BASE_URL = '/api/v2/sample-content';
export function getConfig() {
    return get(`${BASE_URL}/config`);
}
export function deleteSampleContent() {
    return post(`${BASE_URL}/delete-sample-users`);
}
export function resetTrial(payload) {
    return post(`${BASE_URL}/reset-trial`, {
        postData: payload,
    });
}
export function deleteAllContentIncludingCycles(payload) {
    return post(`${BASE_URL}/delete-all`, {
        postData: payload,
    });
}
