import { get, post } from '../request';
import { API_URL } from '../../../app-config/constants';
const BASE = `${API_URL}/notificationLogs`;
export function getLatest({ offset, limit } = {}) {
    const getData = { offset, limit };
    return get(`${BASE}/latest`, { getData });
}
markAsRead.operation = 'UPDATE';
export function markAsRead({ id }) {
    return post(`${BASE}/markAsRead/${id}`);
}
markAsUnread.operation = 'UPDATE';
export function markAsUnread({ id }) {
    return post(`${BASE}/markAsUnread/${id}`);
}
markAllAsRead.operation = 'READ'; // this is a hack...
export function markAllAsRead() {
    return post(`${BASE}/markAllAsRead`);
}
